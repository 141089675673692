<template>
  <main class="page page-home">
    <WelcomeSection />
    <BannerSlider />
    <NewsSection />
    <ActivitySection />
    <WidgetSlider />
    <NewDocumentsSection />
    <UsefulLinksSection />
  </main>
</template>

<script>
import BannerSlider from "./components/BannerSlider.vue";
import WelcomeSection from "./components/WelcomeSection.vue";
import NewsSection from "./components/NewsSection.vue";
import WidgetSlider from "./components/WidgetSlider.vue";
import ActivitySection from "./components/ActivitySection.vue";
import NewDocumentsSection from "./components/NewDocumentsSection.vue";
import UsefulLinksSection from "./components/UsefulLinksSection.vue";
import HOME_PAGE from "gql/pages/HomePage.graphql";

export default {
  name: "HomePage",
  async asyncData({ apollo, store }) {
    await apollo.defaultClient
      .query({
        query: HOME_PAGE,
      })
      .then(({ data }) => {
        store.dispatch("home/save", data);
      });
  },
  metaInfo() {
    const meta = this.$store.state.department?.title;
    return {
      titleTemplate: `%s ${meta}`,
    };
  },
  components: {
    UsefulLinksSection,
    BannerSlider,
    NewDocumentsSection,
    ActivitySection,
    NewsSection,
    WelcomeSection,
    WidgetSlider,
  },
};
</script>

<style lang="stylus">
.page-home {
  display flex
  flex-direction column
  align-items stretch
  justify-content stretch
  gap 50px
  margin-bottom 50px
}
</style>
